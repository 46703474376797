import { ref, computed, nextTick, onActivated, onDeactivated, onMounted, onUnmounted } from 'vue'
import { Grid, Autoplay, FreeMode, Virtual, Scrollbar } from 'swiper/modules'
import { swiperStartAutoPlay, swiperStopAutoPlay } from 'public/src/pages/components/ccc/common/swiper.js' // swiper 实例方法
import expose from 'public/src/services/expose/index.js'
import { deriveFromGlobalCommonInfo } from '../../utils/derive-from-gb-common-info'

const swiperFreeModeWrapperStyle = `
  .swiper-free-mode > .swiper-wrapper {
    transition-timing-function : linear;
  }
`
const staticSwiperParams = {
  spaceBetween: 0,
  slidesPerGroup: 1,
  freeMode: {
    enabled: true,
    sticky: false,
    momentum: true,
    momentumBounce: false,
  },
  shortSwipes: false, // 禁止短距离快速滑动
  resistance: true,
  resistanceRatio: 0.1, // 滑动屏幕阻力，越小阻力越大
  touchRatio: 2, // 对于移动设备，屏幕滑动的速度与鼠标移动的速度之比
  autoplay: {
    // 开启自动滑动
    delay: 1, 
    disableOnInteraction: true, // 禁止拖拽后继续自动滑动
    stopOnLastSlide: true,
  },
  autoplayOutsideControl: true, // 是否外部控制 swiper 的自动轮播开关
}

/**
 * Swiper 滚动条样式
 * 
 * @param {{
 *   classNamePrefix: string
 *   gridCount: import('vue').Ref<number>
 * }} param
 * @returns 
 */
const swiperScrollBarStyle = ({ 
  gridCount,
  classNamePrefix
}) => `
  .${classNamePrefix}__swiper-scrollbar-horizontal {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 4px;
    top: auto;
    z-index: 50;
    height: 3px;
    width: calc(${gridCount.value} * 12px);
    background: #E5E5E5;
  }

  .${classNamePrefix}__swiper-scrollbar-drag {
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    background: #222;
  }
`

/**
 * 配置 SBC Swiper
 * 
 * @template D data item type parameter
 * @template S info summary type parameter
 * @param {{
 *   sbcInfoSummary: import('vue').Ref<S>
 *   sceneData: import('vue').Ref<Record<string, unknown>>,
 *   slidesData: import('vue').Ref<D[]>
 *   virtualData: import('vue').Ref<D[]>
 *   gridCount: import('vue').Ref<number>
 *   debugRecorder?: import('@modules/homepage/utils/debug-print').DebugRecorder
 * }} context
 * @param {{
 *   injectStyles?: string[],
 *   enableScrollBar?: boolean,
 *   scrollBarHorizontalClass?: string,
 *   scrollBarDragClass?: string,
 *   classNamePrefix?: string,
 *   onReturnVisible?: () => void,
 * }} options
 */
export function useSbcSwiper({
  sceneData,
  slidesData,
  virtualData,
  sbcInfoSummary,
  gridCount,
  debugRecorder,
}, {
  injectStyles = [],
  enableScrollBar = false,
  scrollBarHorizontalClass = '',
  scrollBarDragClass = '',
  classNamePrefix = 'category_recommend_dynamic',
  onReturnVisible,
} = {}) {
  const {
    PUBLIC_CDN,
    GB_cssRight: cssRight, 
  } = deriveFromGlobalCommonInfo()

  const swiperContainerClassName = `${classNamePrefix}__swiper`

  const swiperTimer = ref(null)
  const obInstance = ref(null)
  const curTranslate = ref()
  const finalTranslate = ref()

  // Swiper 实例 模板 Ref
  const swiperInstanceRef = ref(null)

  // 只自动滑动一次（用户操作后停止）
  const isFirstAutoPlay = ref(true)

  // 曝光标志是否由自动滑动触发
  const isAutoPlayExpose = ref(false)

  const swiperSlideWidth = computed(() => (
    `calc(100% / ${sbcInfoSummary.value.spacing})`
  ))
  
  const {
    columnCount,
    spacing,
    isAutoSlide = false,
  } = sbcInfoSummary.value || {}
  const swiperDir = cssRight ? 'rtl' : 'ltr'
  const swiperContainerStyle = computed(() => {
    const styl = {}
    if (columnCount === 4) {
      styl.paddingLeft = '0'
    }
    
    return styl
  })
  
  const swiperRunAutoPlay = () => {
    const isHomePage = sceneData.value.pageFrom === 'home'
    const isCampaignsPage = sceneData.value.pageFrom === 'campaigns'
    const topHide = isHomePage ? 88 : 44
    const bottomHide = isHomePage ? 47 : 0
    obInstance.value = new expose({ 
      observeHide: true, 
      exposeRatio: 0.99, 
      mask: [topHide, '0', bottomHide, '0']
    })
    obInstance.value.observe(
      {
        elements: swiperInstanceRef.value,
        once: false,
      },
      ({ exposeDoms }) => {
        if (!swiperInstanceRef.value) return
        if (exposeDoms?.length || isCampaignsPage) {
          // 第一次进入视口时候，等待2s后开始自动播放
          if (isFirstAutoPlay.value) {
            clearTimeout(swiperTimer.value)
            swiperTimer.value = setTimeout(() => {
              swiperStartAutoPlay(swiperInstanceRef.value?.swiper)
              isFirstAutoPlay.value = false
              isAutoPlayExpose.value = true
            }, 2000)
          } else {
            // 第一次开始播放后，再次进入视口时候，直接开始自动播放
            resumeAutoPlay()
          }
        } else {
          clearTimeout(swiperTimer.value)
          pauseAutoPlay()
        }
      },
    )
  }
  const stopAutoPlay = () => {
    // 设置超出边缘回弹速度
    const swiper = swiperInstanceRef.value?.swiper
    if (swiper?.params) {
      swiper.params.speed = 999999
    }
    isFirstAutoPlay.value = false
    if (obInstance.value) {
      // 用户操作后就停止自动滑动
      swiperStopAutoPlay(swiper)
      isAutoPlayExpose.value = false
      clearAutoPlay()
    }
  }
  const pauseAutoPlay = () => {
    // 自动播放是 transition-duration 控制的
    // 所以暂停播放只能先获取此刻的偏移量，再通过设置 translate 来实现
    curTranslate.value = swiperInstanceRef.value?.swiper?.getTranslate()
    finalTranslate.value = swiperInstanceRef.value?.swiper?.translate
    swiperInstanceRef.value?.swiper?.setTranslate(curTranslate.value)
    swiperStopAutoPlay(swiperInstanceRef.value?.swiper)
  }
  const clearAutoPlay = () => {
    clearTimeout(swiperTimer.value)
    swiperStopAutoPlay(swiperInstanceRef.value?.swiper)
    // swiper 视口监听销毁
    obInstance.value?.destroy?.()
    obInstance.value = null
  }
  const resumeAutoPlay = () => {
    const remainTranslate = Math.abs(finalTranslate.value) - Math.abs(curTranslate.value)
    const remainTime = remainTranslate / Math.abs(finalTranslate.value) * (columnCount - 4) * 2000
    remainTime && swiperInstanceRef.value?.swiper?.slideNext(remainTime)
    swiperStartAutoPlay(swiperInstanceRef.value?.swiper)
  }

  // Swiper 初始化
  // Swiper 配置参数
  const getSwiperParams = () => {
    const isNeedScrollBar = enableScrollBar
      ? (Number(spacing) % 1 === 0)
      : false

    return {
      ...staticSwiperParams,
        
      modules: [
        Grid, Autoplay, FreeMode, Virtual,
        ...(isNeedScrollBar ? [Scrollbar] : []),
      ],
      scrollbar: (
        isNeedScrollBar ? {
          hide: false,
          horizontalClass: scrollBarHorizontalClass,
          dragClass: scrollBarDragClass,
          dragSize: 12,
        } : void 0
      ),
  
      // 每页显示的个数，第 5 张图漏出一点
      // 只有四列时，每行展示 4 个; 矩形固定 4.61
      slidesPerView: (
        columnCount === 4 
          ? 4
          : Number(spacing)
      ),
      injectStylesUrls: [
        PUBLIC_CDN + '/pwa_dist/libs/swiper/modules/grid-element.min.css',
      ],
      injectStyles: [
        swiperFreeModeWrapperStyle,
        ...(isNeedScrollBar ? [swiperScrollBarStyle({ gridCount, classNamePrefix })] : []),
        ...injectStyles,
      ],
      virtual: {
        slides: slidesData.value,
        renderExternal: data => {
          const { slides, from, to } = data
          const intToIdx = parseInt(to)
          // Tips: 设置临时数据是为了只更新一次响应式数据 virtualData, 避免多次更新
          let tempVirtualDataLength = virtualData.value.length
          const tempVirtualData = []
  
          if (
            tempVirtualDataLength >= slidesData.value.length 
              || tempVirtualDataLength > intToIdx
          ) {
            return
          }
  
          slides.forEach((item, index) => {
            if (from + index >= virtualData.value.length) {
              tempVirtualData.push(item)
              tempVirtualDataLength++
            }
          })
  
          virtualData.value.push(...tempVirtualData)
        }
      },
      speed: (columnCount - 4) * 135, // 每个 item 滑动时间
      on: {
        init: () => {
          swiperInstanceRef.value?.swiper?.off('resize')
          nextTick(() => {
            swiperStopAutoPlay(swiperInstanceRef.value?.swiper)
            if (enableScrollBar && isAutoSlide && isFirstAutoPlay.value) {
              swiperRunAutoPlay()
            }
          })
        },
        sliderMove: () => {
          stopAutoPlay()
        },
        tap: () => {
          pauseAutoPlay()
          stopAutoPlay()
        },
        click: () => {
          pauseAutoPlay()
          stopAutoPlay()
        },
      },
    }
  }
  const swiperInit = () => {
    let swiperEl = swiperInstanceRef.value
    if (!swiperEl) {
      swiperEl = document.querySelector(`.${swiperContainerClassName}`)
      if (!swiperEl) return // 仍然找不到 swiper 容器，则退出
      swiperInstanceRef.value = swiperEl
    }

    Object.assign(swiperEl, getSwiperParams())
    swiperEl.initialize()
  }

  // Swiper 水合
  const isSwiperHydrated = ref(false)
  const onSwiperHydrated = async () => {
    isSwiperHydrated.value = true
    debugRecorder?.debugLog('SBC 组件 Swiper hydrated')
    await nextTick()
    swiperInit()
  }
  const initRenderCount = (
    sbcInfoSummary.value?.spacing
      ? Math.ceil(Number(sbcInfoSummary.value.spacing))
      : 5
  )

  onActivated(() => {
    if (enableScrollBar && isAutoSlide && isFirstAutoPlay.value) {
      swiperRunAutoPlay()
    } else {
      swiperInstanceRef.value?.swiper?.slideNext(0)
      swiperInstanceRef.value?.swiper?.slidePrev(0)
    }
  })
  onDeactivated(() => {
    let _translate = swiperInstanceRef.value?.swiper?.translate
    if (_translate && cssRight) {
      _translate = -_translate
    }
    clearAutoPlay()
  })
  onMounted(async () => {
    await nextTick()
    swiperInit()
  })

  // 对 swiperInstanceRef 对应 DOM 元素设置观察器
  // 当期重新恢复可见时，重新初始化 swiper
  if (typeof IntersectionObserver !== 'undefined') {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          swiperInit()
          onReturnVisible?.()
        }
      })
    })
    onMounted(() => {
      if (swiperInstanceRef.value) {
        observer.observe(swiperInstanceRef.value)
      }
    })
    onUnmounted(() => {
      if (swiperInstanceRef.value) {
        observer.unobserve(swiperInstanceRef.value)
      }
      observer.disconnect()
    })
  }

  return {
    swiperContainerClassName,
    swiperInstanceRef,
    swiperContainerStyle,
    swiperDir,
    swiperSlideWidth,
    initRenderCount,
    isSwiperHydrated,
    swiperInit,
    swiperRunAutoPlay,
    onSwiperHydrated,
  }
}
