import { computed, toRefs } from 'vue'

/**
 * @typedef {import('@modules/homepage/types/sbc').SbcDynamicMetaData} SbcDynamicMetaData 
 */

/**
 * 从 Vue 组件 props 上提取 CCC 组件的关键信息
 */
export function deriveFromCccCompVueProps(vueProps) {
  const {
    propData: vuePropsPropData,
    sceneData: vuePropsSceneData,
    context: vuePropsContext,
    index: vuePropsIndex,
  } = toRefs(vueProps)

  const compProps = computed(() => (vuePropsPropData.value?.props || {}))
  const compMetaData = computed(() => (compProps.value.metaData || {}))
  const compExtendData = computed(() => (
    vuePropsSceneData.value?.extendData 
    || vuePropsContext.value?.content?.extendParse 
    || {}
  ))

  return {
    vuePropsPropData,
    vuePropsSceneData,
    vuePropsContext,
    vuePropsIndex,
    compProps,
    compMetaData,
    compExtendData,
  }
}
