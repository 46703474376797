/* eslint-disable no-unused-vars */

import { ref, computed, nextTick } from 'vue'
import { deriveFromCccCompVueProps } from '@modules/homepage/composables/derive-from-ccc-comp-vue-props'
import { reportSbcHidden } from '@modules/homepage/ccc-stuff/sbc/report-sbc-hidden'
import { setupRowsAndColsCountByDistributionInStyleZ } from '@modules/homepage/ccc-stuff/sbc/setup-info-summary' 
import { useSbcSwiper } from '@modules/homepage/ccc-stuff/sbc/use-swiper-dynamic'
import { distributeItemsToSlides } from '@modules/homepage/ccc-stuff/sbc/distribute'
import { useHomeSlsLog, SLS_LOG_LEVEL } from '@modules/homepage/composables/use-home-sls-log'
import { useAnalysisData } from '@modules/homepage/composables/use-analysis-data'

/**
 * @typedef {import('@modules/homepage/types/sbc').SbcDynamicInfoSummary} SbcDynamicInfoSummary
 * @typedef {import('@modules/homepage/types/sbc').SbcDynamicDataItem} SbcDynamicDataItem
 * @typedef {import('@modules/homepage/types/sbc').SbcDynamicMetaData} SbcDynamicMetaData 
 */

/** swiper 初始化渲染条数 */
const SWIPER_INIT_RENDER_SLIDE_COUNT = 5
/** 需要裁剪的图片格式 */
const isAnimationImageRegExp = /\.(gif)$/

/**
 * 获取元素卡片样式
 * 
 * @param {{
*   compExtendData: import('vue').ComputedRef<Record<string, unknown>>,
* }} params
*/
function useCardStyle({ compExtendData, compMetaData }) {
  // 卡片化样式
  const isCardStyle = computed(() => {
    const {
      isCardShow = '0',
      margin: marginList = [],
      cardRadius = 0,
    } = compMetaData.value || {}

    if (isCardShow == '0') return false
    if (isCardShow == '1' || cardRadius > 0) return true

    return (
      Array.isArray(marginList) 
      && marginList.some((item) => item > 0)
    )
  })
  const cardStyle = computed(() => {
    const { margin = [], cardRadius = 0 } = compMetaData.value || {}
    const marginList = (
      Array.isArray(margin) && margin.length === 4
        ? margin.slice()
        : Array(4).fill(0)
    )
    const marginStyle = (
      marginList
        .map((item) => `${Number(item)}px`)
        .join(' ')
    )
    const styleResult = {
      margin: marginStyle,
      overflow: 'hidden',
      borderRadius: `${Number(cardRadius)}px`,
    }

    return isCardStyle.value ? styleResult : {}
  })

  return cardStyle
}

/**
 * 获取根元素样式
 * 
 * @param {{
 *   isSkeletonShow: import('vue').Ref<boolean>
 *   compMetaData: import('vue').ComputedRef<SbcDynamicMetaData>
 *   compExtendData: import('vue').ComputedRef<Record<string, unknown>>,
 * }} params
 */
function useRootStyle({
  isSkeletonShow,
  compExtendData,
  compMetaData,
}) {
  // 卡片化样式
  const cardStyle = useCardStyle({ compExtendData, compMetaData })

  // 背景图片样式
  const bgImgStyle = computed(() => {
    const bgImgSrc = (
      compMetaData.value?.bgImage?.src
      || compMetaData.value?.backgroundImg?.src
    )
    if (bgImgSrc) {
      return {
        backgroundSize: '100% 100%',
        backgroundImage: `url(${bgImgSrc})`
      }
    }
    return { background: '#fff' } 
  })

  // 组件根元素样式
  const rootStyle = computed(() => {
    return {
      paddingTop: (
        compMetaData.value.showTopSpacing === '1'
          ? '8px'
          : '0'
      ),
      visibility: (
        isSkeletonShow.value
          ? 'hidden'
          : 'visible'
      ),
      ...cardStyle.value,
      ...bgImgStyle.value,
    }
  })

  return rootStyle
}

/** 
 * @param {{
 *   sbcInfoSummary: import('vue').Ref<SbcDynamicInfoSummary>
 *   vuePropsPropData: import('vue').Ref<Record<string, unknown>>
 *   vuePropsSceneData: import('vue').Ref<Record<string, unknown>>
 *   vuePropsIndex: import('vue').Ref<number>
 *   compMetaData: import('vue').ComputedRef<SbcDynamicMetaData>
 *   itemsDataList: import('vue').ComputedRef<SbcDynamicDataItem[][]>
 *   isShow: import('vue').Ref<boolean>
 * }} param 
 * */
function setupSbcInfoSummary({
  sbcInfoSummary,
  vuePropsPropData,
  vuePropsSceneData,
  compMetaData,
  vuePropsIndex,
  itemsDataList,
  isShow,
}) {
  const {
    sbcFontSize,
    sbcFontThickness,
    sbcLines,
    sbcSpacing,
    sbcStroke,
  } = compMetaData.value?.contentExtra || {}
  const {
    SBCDistribution,
    isAutoSlide,
    imageShape,
  } = compMetaData.value || {}

  sbcInfoSummary.value.fontSize = sbcFontSize || '11'
  sbcInfoSummary.value.fontThickness = sbcFontThickness || 'Regular'
  sbcInfoSummary.value.textLines = sbcLines || '2'
  sbcInfoSummary.value.spacing = sbcSpacing || '4.7'
  sbcInfoSummary.value.stroke = sbcStroke || 'false'

  let rowCount = compMetaData.value?.rows || 1
  const listLength = itemsDataList.value.length

  if (listLength < 5) {
    // 不满一行，不显示本组件
    isShow.value = false
    reportSbcHidden({
      vuePropsPropData,
      vuePropsSceneData,
      vuePropsIndex,
    })
  }

  const counts = setupRowsAndColsCountByDistributionInStyleZ({
    sbcDistribution: SBCDistribution,
    listLength,
    rowCount,
  })
  sbcInfoSummary.value.rowCount = counts.rowCount
  sbcInfoSummary.value.columnCount = counts.columnCount

  // 设置排布方式（换行阈值）
  sbcInfoSummary.value.distribution = SBCDistribution || '4'

  // 设置一行几列样式
  sbcInfoSummary.value.spacing = sbcSpacing || '4.7'

  // 设置是否自动滑动
  sbcInfoSummary.value.isAutoSlide = isAutoSlide === '1'

  // 设置图片形状
  sbcInfoSummary.value.imageShape = imageShape || 'circle'
}

export function useStateStore(vueProps) {
  const {
    vuePropsPropData,
    vuePropsSceneData,
    vuePropsIndex,
    compProps,
    compMetaData,
    compExtendData,
  } = deriveFromCccCompVueProps(vueProps)

  const reportFieldMissing = (fieldName) => {
    // 发送日志报告异常
    useHomeSlsLog({
      logLevel: SLS_LOG_LEVEL.ERROR,
      tag: 'client_home_error_total',
      message: 'CCCShownFilterError',
      reason: `items 中存在没有 ${fieldName} 字段的数据项，请关注！`,
    }, {
      propData: vuePropsPropData,
      sceneData: vuePropsSceneData,
      index: vuePropsIndex,
    })
  }

  /** SBC 入口项数据列表 */
  const itemsDataList = computed(() => {
    const dataList = (compProps.value?.items || [])

    // 处理数据
    dataList.forEach(item => {
      if (item.image?.src) {
        // 常规图片类型，让 BaseImg 处理转为 webp、并裁剪
        // 动态图保持原样不动、避免图花掉
        if (isAnimationImageRegExp.test(item.image.src)) {
          item._FE_isUnCut_ = 0
          item._FE_isAnimationImg_ = 1
        }
      } else {
        // 没有分类图，发送日志报告异常
        reportFieldMissing(item.image ? 'image.src' : 'image')
      }
      
      if (!item.smallTitle) {
        // 没有分类标题，发送日志报告异常
        reportFieldMissing('smallTitle')
      }
    })

    return dataList
  })

  /** 是否展示组件 */
  const isShow = ref(true)

  /** 是否展示骨架屏 */
  const isSkeletonShow = ref(false)

  /** 组件根元素样式 */
  const rootStyle = useRootStyle({
    isSkeletonShow,
    compExtendData,
    compMetaData,
  })

  /** 入口坑位图背景色（图片可能有透明的部分） */
  const imageStyle = computed(() => ({
    backgroundColor: (compMetaData.value?.contentExtra || {}).iconBottomColor || '#F0EEEC'
  }))

  /**
   * @type {import('vue').ComputedRef<(item: SbcDynamicDataItem) => CSSStyleDeclaration} 
   * 角标样式 
   */
  const getCornerMarkStyle = (item) => ({
    backgroundImage: (
      item.markImage?.src 
        ? `url("${item.markImage.src}")` 
        : undefined
    ),
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  })

  /** 腰带样式 */
  const beltStyle = computed(() => ({
    backgroundImage: compMetaData.value?.beltImage ? `url("${compMetaData.value.beltImage.src}")` : undefined,
    color: compMetaData.value.beltTextColor || '#000000',
    backgroundSize: 'inherit',
    backgroundPosition: 'center',
  }))
  /** 是否展示腰带 */
  const isShowBelt = computed(() => Boolean(compMetaData.value.showBelt === '1'))

  /** 
   * 入口单元格数据
   * @type {import('vue').Ref<SbcDynamicDataItem[][]>}
   */
  const slidesData = ref([])

  /** 
   * swiper 虚拟渲染数据
   * @type {import('vue').Ref<SbcDynamicDataItem[][]>
   * */
  const virtualData = ref([])

  /** @type {SbcDynamicInfoSummary} */
  const sbcInfoInitValue = {
    rowCount: 1,
    columnCount: 4,
    distribution: '4',
    spacing: '4.7',
    fontSize: '11',
    fontThickness: 'Regular',
    textLines: '2',
    isAutoSlide: false,
    imageShape: 'circle',
  }
  /** 
   * 汇总 SBC 组件信息
   * @type {import('vue').Ref<SbcDynamicInfoSummary>}
   * */
  const sbcInfoSummary = ref(sbcInfoInitValue)

  // ... 其他杂项状态
  const isFirstPage = computed(() => (
    Boolean(vuePropsPropData.value?.isFirstPage)
  ))
  const cateNameStyle = computed(() => {
    const {
      cateNameColor,
    } = compMetaData.value
    const {
      fontSize = '11',
      fontThickness = 'Regular',
    } = sbcInfoSummary.value

    return {
      fontSize: `${fontSize}px`,
      fontWeight: fontThickness === 'Medium' ? '500' : '400',
      color: cateNameColor,
    }
  })
  const lineClampClass = computed(() => {
    const { textLines } = sbcInfoSummary.value
    return (
      textLines === '3'
        ? 'three-lines'
        : 'two-lines'
    )
  })

  // 埋点
  /**
   * 
   * @param {*} item 
   * @param {number} index 
   * @param {'tap' | 'expose'} metricType 
   * @returns 
   */
  const getDataForMetrics = (item, metricType, rowIndex, colIndex) => {
    const itemLoc = `${rowIndex + 1}_${colIndex + 1}`
    return useAnalysisData(
      (
        metricType === 'tap' 
          ? '2-22-2' 
          : '2-22-1'
      ),
      {
        item: {
          ...item,
          item_loc: itemLoc,
          oprt_loc: String(vuePropsIndex.value + 1),
        },
        index: itemLoc,
        useBffApi: true,
      }
    )
  }

  // #region 初始化数据

  // --- 1. 初始化 SBC 组件信息
  setupSbcInfoSummary({
    sbcInfoSummary,
    vuePropsPropData,
    vuePropsSceneData,
    vuePropsIndex,
    compMetaData,
    itemsDataList,
    isShow,
  })

  // --- 2. 完成 Swiper 配置
  const swiperComposables = useSbcSwiper({
    sceneData: vuePropsSceneData,
    slidesData,
    virtualData,
    sbcInfoSummary,
  }, {
    enableScrollBar: true,
    classNamePrefix: 'category_recommend_dynamic_bff',
    scrollBarHorizontalClass: 'category_recommend_dynamic_bff__swiper-scrollbar-horizontal',
    scrollBarDragClass: 'category_recommend_dynamic_bff__swiper-scrollbar-drag',
  })

  // --- 3. 接着处理好 slidesData 给 Vue 渲染 swiper slide 时排布
  distributeItemsToSlides({
    sbcInfoSummary,
    itemsDataList,
    slidesData,
  })

  // -- 4. 从排布好的 swiper slide 切出初始渲染数据
  virtualData.value = slidesData.value.slice(0, SWIPER_INIT_RENDER_SLIDE_COUNT)

  // #endregion

  return {
    isShow,
    isSkeletonShow,
    isFirstPage,
    isShowBelt,
    sbcInfoSummary,
    rootStyle,
    beltStyle,
    imageStyle,
    cateNameStyle,
    lineClampClass,
    compMetaData,
    slidesData,
    virtualData,
    ...swiperComposables,
    getDataForMetrics,
    getCornerMarkStyle,
  }
}
