import { getCurrentInstance } from 'vue'

/**
 * @param {string} eventId
 * @param {{
 *   item: any,
 *   index: number,
 *   prefix?: string,
 *   callback?: (...args: any[]) => any,
 *   setCode?: boolean | string,
 *   useBffApi?: boolean,
 * } & Record<string, unknown>} params
 * 
 * @returns {{
 *   id: string,
 *   code: string,
 *   prefix: string,
 *   data: any,
 *   callback: ((...args: any[]) => any) | ''
 * }}
 */
export function useAnalysisData(
  eventId,
  {
    item,
    index,
    prefix,
    callback,
    setCode = false,
    useBffApi = false,
    ...args
  } = {},
) {
  const { proxy: currentVueComponent } = getCurrentInstance()
  const {
    sceneData: vuePropsSceneData,
    propData: vuePropsPropData,
    index: vuePropsIndex,
  } = currentVueComponent.$props
  
  const data = {
    sceneData: vuePropsSceneData,
    propData: vuePropsPropData,
    compIndex: vuePropsIndex,       // 组件所在位置
    item,                           // item
    index,                          // 当前item在组件中的位置
    useBffApi,
    ...args
  }

  let code = ''
  if (typeof setCode === 'boolean' && setCode) code = this.code
  if (typeof setCode === 'string') code = setCode

  return {
    id: eventId,
    code,
    prefix: prefix ? prefix : vuePropsSceneData.pageFrom,
    data,
    callback: callback && typeof callback === 'function' ? callback : ''
  }
}
